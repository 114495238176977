import React from 'react';

import GuestLayout from '../layouts/GuestLayout';

import './faq.scss';

export default function Faq() {
  return (
    <GuestLayout>
      <div className="faq-page">
        <div className="title">FAQ</div>
        <div className="question">
          <div className="label">
            Comment être sur que ma commande est bien prise en compte ?
          </div>
          <div className="answer">
            Quand vous passez une commande, vous recevez un email de
            confirmation. Si vous n’avez pas reçu d’email de confirmation
            lorsque vous avez confirmé votre commande, vérifiez votre boite des
            indésirables/spams ou contactez directement notre support à
            sav@wiltee.com et nous vérifierons si votre commande a été prise en
            compte ou non.
          </div>
        </div>
        <div className="question">
          <div className="label">Quels sont les délais de livraison ?</div>
          <div className="answer">
            Nos délais de livraison varient entre 7 et 12 jours ouvrés.
          </div>
        </div>
        <div className="question">
          <div className="label">Comment puis-je suivre ma commande ?</div>
          <div className="answer">
            Vous recevez par email un numéro de suivi sur lequel vous pouvez
            suivre votre commande.
          </div>
        </div>
        <div className="question">
          <div className="label">Puis-je annuler ma commande ?</div>
          <div className="answer">
            Vous pouvez annuler votre commande maximum 24h après l'avoir passé.
            Pour ce faire, merci d'envoyer un e-mail à sav@wiltee.com avec en
            objet votre numéro de commande.
          </div>
        </div>
        <div className="question">
          <div className="label">Comment effectuer un retour ?</div>
          <div className="answer">
            Pour retourner un produit, vous devez nous le renvoyer (dans les 14
            jours à compter de la réception de votre commande, au-delà votre
            demande ne sera pas traitée) à :
            <br />
            <br />
            Wiltee
            <br />
            127 rue charles tillon
            <br />
            93 300 Aubervilliers
            <br />
            <br />
            Avec un petit mot indiquant que c’est un retour + votre numéro de
            commande ou l'adresse email avec laquelle vous avez commandé. Si le
            numéro de commande n'est pas présent sur votre petit mot, le retour
            sera considéré comme nul et ne sera pas traité.
            <br />
            Veillez également à bien conserver votre numéro de suivi colis car
            il vous sera demandé pour preuve d'envoi de votre produit.
            <br />
            Une fois le colis reçu, nous vous rembourserons le produit (hors
            frais de port) sous 10 jours.
            <br />
            Les frais de port retour sont à votre charge.
          </div>
        </div>
      </div>
    </GuestLayout>
  );
}
