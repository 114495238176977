import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from '@paypal/react-paypal-js';
import axios from 'axios';

import './checkout.scss';
import GuestLayout from '../layouts/GuestLayout';
import { useBasket } from '../hooks/basket/Basket';
import { useStore } from '../hooks/store/Store';
import { Loader } from '../components/loader';

const stripePromise = loadStripe('pk_live_hRa50558MsWjLkCklKXYcWfb');
//const stripePromise = loadStripe('pk_test_Miqit4OMoZwtRTLhcr1clhZC');

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const initialOptions = {
  'client-id':
    'Aep13MqTbpJjxo5QfdwU5BPNpXeodcrqYtw1TTuHVWgBmB-bYEjFFVxGGLfJt9XmE9-6dqwhMSYO7g2A', //"AdcHGaU_0RAnhx3oMTKiTMqOZegOpdL9TFt9CXgiIkQufzEOkpPhmrS0E0oDhzzfZapzgFhVHsvOnWvz",
  currency: 'EUR',
  intent: 'authorize',
  //"data-client-token": "abc123xyz==",
};

const CheckoutForm = ({ onLoad, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const basket = useBasket();
  const [form, setForm] = useState({
    email: '',
    firstname: '',
    lastname: '',
    address: '',
    addressComplement: '',
    city: '',
    zipcode: '',
    country: '68',
    phone: '',
  });
  const [errors, setErrors] = useState({});
  const [channel, setChannel] = useState('stripe');
  const paypalData = useRef(null);
  const [paymentError, setPaymentError] = useState(false);

  useEffect(() => {
    basket.setShippingCountryId(parseInt(form.country, 10));
  }, [form.country]);

  useEffect(() => {
    if (document.querySelector('.error')) {
      document.querySelector('.error').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [errors]);

  const sendOrder = (paymentData) => {
    setPaymentError(false);
    axios
      .post(`/_api/checkout/new-order?t=${Date.now()}`, {
        lines: basket.basket.lines,
        couponCode: basket.basket.coupon ? basket.basket.coupon.code : null,
        paymentData,
        channel,
        email: form.email,
        shippingAddress: {
          firstname: form.firstname,
          lastname: form.lastname,
          address: form.address,
          addressComplement: form.addressComplement,
          city: form.city,
          zipcode: form.zipcode,
          countryId: basket.shipping.countryId,
          phone: form.phone,
        },
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        onError(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Une erreur est survenue.'
        );
        setPaymentError(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Une erreur est survenue.'
        );
      });
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (channel === 'stripe' && (!stripe || !elements)) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const errors = {};
    if (form.email.length <= 0) {
      errors.email = 'Ce champs est obligatoire';
    }
    if (form.firstname.length <= 0) {
      errors.firstname = 'Ce champs est obligatoire';
    }
    if (form.lastname.length <= 0) {
      errors.lastname = 'Ce champs est obligatoire';
    }
    if (form.address.length <= 0) {
      errors.address = 'Ce champs est obligatoire';
    }
    if (form.city.length <= 0) {
      errors.city = 'Ce champs est obligatoire';
    }
    if (form.zipcode.length <= 0) {
      errors.zipcode = 'Ce champs est obligatoire';
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      onLoad();
      setPaymentError(false);

      if (channel === 'stripe') {
        axios
          .post(`/_api/checkout/generate-payment-intent?t=${Date.now()}`, {
            countryId: basket.shipping.countryId,
            lines: basket.basket.lines,
            couponCode: basket.basket.coupon ? basket.basket.coupon.code : null,
          })
          .then(({ data }) => {
            const cardElement = elements.getElement(CardNumberElement);
            stripe
              .confirmCardPayment(data.clientSecret, {
                payment_method: {
                  card: cardElement,
                },
              })
              .then(function (result) {
                if (result.error) {
                  onError(result.error.message);
                  setPaymentError(result.error.message);
                  // Show error to your customer (e.g., insufficient funds)
                  console.log(result.error);
                } else {
                  // The payment has been processed!
                  if (result.paymentIntent.status === 'succeeded') {
                    sendOrder(result.paymentIntent);
                  }
                }
              });
          });
      } else {
        sendOrder(paypalData.current);
      }
    }
  };

  const onFormChange = (key) => {
    return (event) => {
      const value = event.target.value;
      setForm((prevForm) => {
        const newForm = Object.assign({}, prevForm);
        _.set(newForm, key, value);
        return newForm;
      });
    };
  };

  const [orderID, setOrderID] = useState(false);

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: basket.getTotalTTC(),
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  }

  function onPaypalApprove(data, actions) {
    paypalData.current = data;
    setPaymentError(false);
    setChannel('paypal');

    axios
      .get(
        `https://api.sandbox.paypal.com/v2/checkout/orders/${data.orderID}`,
        {
          headers: {
            authorization: `Bearer ${data.facilitatorAccessToken}`,
          },
        }
      )
      .then((response) => {
        setForm({
          email: response.data.payer.email_address,
          firstname: response.data.payer.name.given_name,
          lastname: response.data.payer.name.surname,
          address:
            response.data.purchase_units[0].shipping.address.address_line_1,
          addressComplement:
            response.data.purchase_units[0].shipping.address.address_line_2,
          city: response.data.purchase_units[0].shipping.address.admin_area_2,
          zipcode: response.data.purchase_units[0].shipping.address.postal_code,
          country: '68',
          phone: '',
        });
      });
  }

  function onPaypalCancel(data, actions) {
    console.log('cancel', data);
    setPaymentError("Le payment n'a pu aboutir");
  }

  function onPaypalError(data, actions) {
    console.log('cancel', data);
    setPaymentError("Le payment n'a pu aboutir");
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="wrapper">
        {/* { channel !== 'paypal' &&
          <div style={{ zIndex: 1 }}>
            <div style={{ marginBottom: '4px' }}>Payer par</div>
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                fundingSource={FUNDING.PAYPAL}
                createOrder={(data, actions) => createOrder(data, actions)}
                forceReRender={[basket.getTotalTTC()]}
                onApprove={onPaypalApprove}
                onCancel={onPaypalCancel}
                onError={onPaypalError}
                onShippingChange={(...args) => console.log(args)}
              />
            </PayPalScriptProvider>
          </div>
        } */}
        <div className="step">
          <div className="title">Informations client</div>
          <label htmlFor="email">Adresse email *</label>
          <input
            type="text"
            disabled={channel === 'paypal'}
            id="email"
            value={form.email}
            onChange={onFormChange('email')}
            placeholder="exemple@email.com"
          />
          {errors.email && <div className="error">{errors.email}</div>}
          <div className="tips">
            Nous serons en mesure de vous informer sur les détails de votre
            commande
          </div>
        </div>

        <div className="step">
          <div className="title">Détails de livraison</div>
          <div className="box">
            <div>
              <label htmlFor="firstname">Prénom *</label>
              <input
                type="text"
                id="firstname"
                value={form.firstname}
                onChange={onFormChange('firstname')}
              />
              {errors.firstname && (
                <div className="error">{errors.firstname}</div>
              )}
            </div>
            <div>
              <label htmlFor="lastname">Nom *</label>
              <input
                type="text"
                id="lastname"
                value={form.lastname}
                onChange={onFormChange('lastname')}
              />
              {errors.lastname && (
                <div className="error">{errors.lastname}</div>
              )}
            </div>
          </div>
          <label htmlFor="address">Adresse *</label>
          <input
            type="text"
            id="address"
            value={form.address}
            onChange={onFormChange('address')}
          />
          {errors.address && <div className="error">{errors.address}</div>}
          <label htmlFor="addressComplement">Complément d'adresse</label>
          <input
            type="text"
            id="addressComplement"
            value={form.addressComplement}
            onChange={onFormChange('addressComplement')}
          />
          {errors.addressComplement && (
            <div className="error">{errors.addressComplement}</div>
          )}
          <div className="box">
            <div>
              <label htmlFor="city">Ville *</label>
              <input
                type="text"
                id="city"
                value={form.city}
                onChange={onFormChange('city')}
              />
              {errors.city && <div className="error">{errors.city}</div>}
            </div>
            <div>
              <label htmlFor="zipcode">Code postal *</label>
              <input
                type="text"
                id="zipcode"
                value={form.zipcode}
                onChange={onFormChange('zipcode')}
              />
              {errors.zipcode && <div className="error">{errors.zipcode}</div>}
            </div>
          </div>
          <div className="box">
            <div>
              <label htmlFor="country">Pays *</label>
              <select
                name="country"
                id="country"
                value={form.country}
                onChange={onFormChange('country')}
              >
                <option value="1">country_default</option>
                <option value="2">Afghanistan</option>
                <option value="3">South_Africa</option>
                <option value="4">Albania</option>
                <option value="5">Algeria</option>
                <option value="6">Germany</option>
                <option value="7">Andorra</option>
                <option value="8">Angola</option>
                <option value="9">Anguilla</option>
                <option value="10">Antarctica</option>
                <option value="11">Antigua_Barbuda</option>
                <option value="13">Saudi_Arabia</option>
                <option value="14">Argentina</option>
                <option value="15">Armenia</option>
                <option value="16">Aruba</option>
                <option value="17">Australia</option>
                <option value="18">Austria</option>
                <option value="19">Azerbaijan</option>
                <option value="20">Benin</option>
                <option value="21">Bahamas</option>
                <option value="22">Bahrain</option>
                <option value="23">Bangladesh</option>
                <option value="24">Barbados</option>
                <option value="25">Palau</option>
                <option value="26">Belgium</option>
                <option value="27">Belize</option>
                <option value="28">Bermuda</option>
                <option value="29">Bhutan</option>
                <option value="30">Belarus</option>
                <option value="31">Myanmar</option>
                <option value="32">Bolivia</option>
                <option value="33">Bosnia_and_Herzegovina</option>
                <option value="34">Botswana</option>
                <option value="35">Brazil</option>
                <option value="36">Brunei</option>
                <option value="37">Bulgaria</option>
                <option value="38">Burkina_Faso</option>
                <option value="39">Burundi</option>
                <option value="40">Ivory_Coast</option>
                <option value="41">Cambodia</option>
                <option value="42">Cameroon</option>
                <option value="43">Canada</option>
                <option value="44">Cape_Verde</option>
                <option value="45">Chile</option>
                <option value="46">China</option>
                <option value="47">Cyprus</option>
                <option value="48">Colombia</option>
                <option value="49">Comoros</option>
                <option value="50">Congo</option>
                <option value="51">North_Korea</option>
                <option value="52">South_Korea</option>
                <option value="53">Costa_Rica</option>
                <option value="54">Croatia</option>
                <option value="55">Cuba</option>
                <option value="56">Denmark</option>
                <option value="57">Djibouti</option>
                <option value="58">Dominica</option>
                <option value="59">Egypt</option>
                <option value="60">United_Arab_Emirates</option>
                <option value="61">Ecuador</option>
                <option value="62">Eritrea</option>
                <option value="63">Spain</option>
                <option value="64">Estonia</option>
                <option value="65">United_States</option>
                <option value="66">Ethiopia</option>
                <option value="67">Finland</option>
                <option value="68" selected="">
                  France
                </option>
                <option value="69">Georgia</option>
                <option value="70">Gabon</option>
                <option value="71">Gambia</option>
                <option value="72">Ghana</option>
                <option value="73">Gibraltar</option>
                <option value="74">Greece</option>
                <option value="75">Grenada</option>
                <option value="76">Greenland</option>
                <option value="77">Guadeloupe</option>
                <option value="78">Guam</option>
                <option value="79">Guatemala</option>
                <option value="80">Guinea</option>
                <option value="81">Equatorial_Guinea</option>
                <option value="82">Guinea_Bissau</option>
                <option value="83">Guyana</option>
                <option value="85">Haiti</option>
                <option value="86">Honduras</option>
                <option value="87">Hong_Kong</option>
                <option value="88">Hungary</option>
                <option value="89">Norfolk_Island</option>
                <option value="90">Cayman_Islands</option>
                <option value="91">Cook_Islands</option>
                <option value="92">Faroe_Islands</option>
                <option value="93">Falkland_Islands</option>
                <option value="94">Fiji</option>
                <option value="95">Marshall_Islands</option>
                <option value="96">Solomon_Islands</option>
                <option value="97">Virgin_Islands_US</option>
                <option value="98">Virgin_Islands_British</option>
                <option value="99">India</option>
                <option value="100">Indonesia</option>
                <option value="101">Iran</option>
                <option value="102">Iraq</option>
                <option value="103">Ireland</option>
                <option value="104">Iceland</option>
                <option value="105">Israel</option>
                <option value="106">Italy</option>
                <option value="107">Jamaica</option>
                <option value="108">Japan</option>
                <option value="109">Jordan</option>
                <option value="110">Kazakhstan</option>
                <option value="111">Kenya</option>
                <option value="112">Kyrgyzstan</option>
                <option value="113">Kiribati</option>
                <option value="114">Kuwait</option>
                <option value="115">Laos</option>
                <option value="116">Lesotho</option>
                <option value="117">Latvia</option>
                <option value="118">Lebanon</option>
                <option value="119">Liberia</option>
                <option value="120">Libya</option>
                <option value="121">Liechtenstein</option>
                <option value="122">Lithuania</option>
                <option value="123">Luxembourg</option>
                <option value="124">Madagascar</option>
                <option value="125">Malaysia</option>
                <option value="126">Malawi</option>
                <option value="127">Maldives</option>
                <option value="128">Mali</option>
                <option value="129">Malta</option>
                <option value="130">Morocco</option>
                <option value="131">Martinique</option>
                <option value="132">Mauritius</option>
                <option value="133">Mauritania</option>
                <option value="135">Mexico</option>
                <option value="136">Micronesia</option>
                <option value="137">Moldova</option>
                <option value="138">Monaco</option>
                <option value="139">Mongolia</option>
                <option value="140">Montserrat</option>
                <option value="141">Mozambique</option>
                <option value="142">Nepal</option>
                <option value="143">Namibia</option>
                <option value="144">Nauru</option>
                <option value="145">Nicaragua</option>
                <option value="146">Niger</option>
                <option value="147">Nigeria</option>
                <option value="148">Niue</option>
                <option value="149">Norway</option>
                <option value="151">New_Zealand</option>
                <option value="152">Oman</option>
                <option value="153">Uganda</option>
                <option value="154">Uzbekistan</option>
                <option value="155">Peru</option>
                <option value="156">Pakistan</option>
                <option value="157">Panama</option>
                <option value="158">Papua_New_Guinea</option>
                <option value="159">Paraguay</option>
                <option value="160">Netherlands</option>
                <option value="161">Philippines</option>
                <option value="162">Poland</option>
                <option value="164">Puerto_Rico</option>
                <option value="165">Portugal</option>
                <option value="166">Qatar</option>
                <option value="167">Central_African_Republic</option>
                <option value="168">Congo</option>
                <option value="169">Dominican_Republic</option>
                <option value="170">Czech_Republic</option>
                <option value="172">Romania</option>
                <option value="173">Great_Britain</option>
                <option value="174">Wales</option>
                <option value="175">Scotland</option>
                <option value="176">Northern_Ireland</option>
                <option value="177">England</option>
                <option value="178">Russia</option>
                <option value="179">Rwanda</option>
                <option value="180">Senegal</option>
                <option value="181">Saint_Kitts_and_Nevis</option>
                <option value="182">San_Marino</option>
                <option value="183">Vatican</option>
                <option value="184">Salvador</option>
                <option value="185">Samoa</option>
                <option value="186">Seychelles</option>
                <option value="187">Sierra_Leone</option>
                <option value="188">Singapore</option>
                <option value="189">Slovenia</option>
                <option value="190">Slovakia</option>
                <option value="191">Somalia</option>
                <option value="192">Sudan</option>
                <option value="193">Sri_Lanka</option>
                <option value="194">Sweden</option>
                <option value="195">Switzerland</option>
                <option value="196">Suriname</option>
                <option value="197">Swaziland</option>
                <option value="198">Syria</option>
                <option value="199">Taiwan</option>
                <option value="200">Tajikistan</option>
                <option value="201">Tanzania</option>
                <option value="202">Chad</option>
                <option value="203">Thailand</option>
                <option value="204">Togo</option>
                <option value="205">Tokelau</option>
                <option value="206">Tonga</option>
                <option value="207">Trinidad_Tobago</option>
                <option value="208">Tunisia</option>
                <option value="209">Turkmenistan</option>
                <option value="210">Turkey</option>
                <option value="211">Tuvalu</option>
                <option value="212">Ukraine</option>
                <option value="213">Uruguay</option>
                <option value="214">Serbia</option>
                <option value="215">Montenegro</option>
                <option value="216">Vanuatu</option>
                <option value="217">Venezuela</option>
                <option value="218">Viet_Nam</option>
                <option value="220">Yemen</option>
                <option value="221">Zambia</option>
                <option value="222">Zimbabwe</option>
                <option value="223">FYROM</option>
                <option value="224">Reunion_Island</option>
                <option value="225">Saint_Pierre_and_Miquelon</option>
                <option value="226">Honk-Kong</option>
                <option value="227">Windward Islands</option>
                <option value="228">Nouvelle caldonie</option>
                <option value="229">Guyane</option>
                <option value="230">Republique democratique du Congo</option>
                <option value="231">Mayotte</option>
              </select>
              {errors.country && <div className="error">{errors.country}</div>}
            </div>
            <div>
              <label htmlFor="phone">Téléphone</label>
              <input
                type="text"
                id="phone"
                value={form.phone}
                onChange={onFormChange('phone')}
              />
              <div className="tips">
                Information qui aide à une livraison rapide
              </div>
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
          </div>
        </div>

        <div className="step">
          <div className="title">
            <div className="text">Paiement</div>
            <div className="suf">
              <svg
                style={{ marginRight: '5px' }}
                xmlns="http://www.w3.org/2000/svg"
                className="w-front-icons h-front-icons"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Transaction sécurisée via SSL
            </div>
          </div>
          {channel === 'stripe' && (
            <>
              <label>Numéro de carte *</label>
              <div className="input">
                <CardNumberElement />
              </div>
              <div className="box">
                <div>
                  <label>Date d'expiration *</label>
                  <div className="input">
                    <CardExpiryElement />
                  </div>
                </div>
                <div>
                  <label>CVC *</label>
                  <div className="input">
                    <CardCvcElement />
                  </div>
                </div>
              </div>

              <div className="ctas">
                <button className="buy" type="submit" disabled={!stripe}>
                  Payer par carte bleue
                </button>
                {paymentError && (
                  <div
                    style={{
                      padding: '5px',
                      textAlign: 'center',
                      color: 'red',
                    }}
                  >
                    {paymentError}
                  </div>
                )}
                <ul className="card-list">
                  <li className="cardBrand-visa">Visa</li>
                  <li className="cardBrand-masterCard">Master Card</li>
                  <li className="cardBrand-americanExpress">
                    American Express
                  </li>
                </ul>
              </div>
              <div className="tips">
                <div>
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 150 34"
                    width="150px"
                  >
                    <title>Powered by Stripe - black</title>
                    <path d="M146,0H3.73A3.73,3.73,0,0,0,0,3.73V30.27A3.73,3.73,0,0,0,3.73,34H146a4,4,0,0,0,4-4V4A4,4,0,0,0,146,0Zm3,30a3,3,0,0,1-3,3H3.73A2.74,2.74,0,0,1,1,30.27V3.73A2.74,2.74,0,0,1,3.73,1H146a3,3,0,0,1,3,3Z" />
                    <path d="M17.07,11.24h-4.3V22h1.92V17.84h2.38c2.4,0,3.9-1.16,3.9-3.3S19.47,11.24,17.07,11.24Zm-.1,5H14.69v-3.3H17c1.38,0,2.11.59,2.11,1.65S18.35,16.19,17,16.19Z" />
                    <path d="M25.1,14a3.77,3.77,0,0,0-3.8,4.09,3.81,3.81,0,1,0,7.59,0A3.76,3.76,0,0,0,25.1,14Zm0,6.67c-1.22,0-2-1-2-2.58s.76-2.58,2-2.58,2,1,2,2.58S26.31,20.66,25.1,20.66Z" />
                    <polygon points="36.78 19.35 35.37 14.13 33.89 14.13 32.49 19.35 31.07 14.13 29.22 14.13 31.59 22.01 33.15 22.01 34.59 16.85 36.03 22.01 37.59 22.01 39.96 14.13 38.18 14.13 36.78 19.35" />
                    <path d="M44,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,47.49,20L46,19.38a1.78,1.78,0,0,1-1.83,1.26A2.12,2.12,0,0,1,42,18.47h5.52v-.6C47.54,15.71,46.32,14,44,14Zm-1.93,3.13A1.92,1.92,0,0,1,44,15.5a1.56,1.56,0,0,1,1.69,1.62Z" />
                    <path d="M50.69,15.3V14.13h-1.8V22h1.8V17.87a1.89,1.89,0,0,1,2-2,4.68,4.68,0,0,1,.66,0v-1.8c-.14,0-.3,0-.51,0A2.29,2.29,0,0,0,50.69,15.3Z" />
                    <path d="M57.48,14a3.83,3.83,0,0,0-3.75,4.09,3.79,3.79,0,0,0,3.83,4.09A3.47,3.47,0,0,0,60.93,20l-1.54-.59a1.78,1.78,0,0,1-1.83,1.26,2.12,2.12,0,0,1-2.1-2.17H61v-.6C61,15.71,59.76,14,57.48,14Zm-1.93,3.13a1.92,1.92,0,0,1,1.92-1.62,1.56,1.56,0,0,1,1.69,1.62Z" />
                    <path d="M67.56,15a2.85,2.85,0,0,0-2.26-1c-2.21,0-3.47,1.85-3.47,4.09s1.26,4.09,3.47,4.09a2.82,2.82,0,0,0,2.26-1V22h1.8V11.24h-1.8Zm0,3.35a2,2,0,0,1-2,2.28c-1.31,0-2-1-2-2.52s.7-2.52,2-2.52c1.11,0,2,.81,2,2.29Z" />
                    <path d="M79.31,14A2.88,2.88,0,0,0,77,15V11.24h-1.8V22H77v-.83a2.86,2.86,0,0,0,2.27,1c2.2,0,3.46-1.86,3.46-4.09S81.51,14,79.31,14ZM79,20.6a2,2,0,0,1-2-2.28v-.47c0-1.48.84-2.29,2-2.29,1.3,0,2,1,2,2.52S80.25,20.6,79,20.6Z" />
                    <path d="M86.93,19.66,85,14.13H83.1L86,21.72l-.3.74a1,1,0,0,1-1.14.79,4.12,4.12,0,0,1-.6,0v1.51a4.62,4.62,0,0,0,.73.05,2.67,2.67,0,0,0,2.78-2l3.24-8.62H88.82Z" />
                    <path d="M125,12.43a3,3,0,0,0-2.13.87l-.14-.69h-2.39V25.53l2.72-.59V21.81a3,3,0,0,0,1.93.7c1.94,0,3.72-1.59,3.72-5.11C128.71,14.18,126.91,12.43,125,12.43Zm-.65,7.63a1.61,1.61,0,0,1-1.28-.52l0-4.11a1.64,1.64,0,0,1,1.3-.55c1,0,1.68,1.13,1.68,2.58S125.36,20.06,124.35,20.06Z" />
                    <path d="M133.73,12.43c-2.62,0-4.21,2.26-4.21,5.11,0,3.37,1.88,5.08,4.56,5.08a6.12,6.12,0,0,0,3-.73V19.64a5.79,5.79,0,0,1-2.7.62c-1.08,0-2-.39-2.14-1.7h5.38c0-.15,0-.74,0-1C137.71,14.69,136.35,12.43,133.73,12.43Zm-1.47,4.07c0-1.26.77-1.79,1.45-1.79s1.4.53,1.4,1.79Z" />
                    <path d="M113,13.36l-.17-.82h-2.32v9.71h2.68V15.67a1.87,1.87,0,0,1,2.05-.58V12.54A1.8,1.8,0,0,0,113,13.36Z" />
                    <path d="M99.46,15.46c0-.44.36-.61.93-.61a5.9,5.9,0,0,1,2.7.72V12.94a7,7,0,0,0-2.7-.51c-2.21,0-3.68,1.18-3.68,3.16,0,3.1,4.14,2.6,4.14,3.93,0,.52-.44.69-1,.69a6.78,6.78,0,0,1-3-.9V22a7.38,7.38,0,0,0,3,.64c2.26,0,3.82-1.15,3.82-3.16C103.62,16.12,99.46,16.72,99.46,15.46Z" />
                    <path d="M107.28,10.24l-2.65.58v8.93a2.77,2.77,0,0,0,2.82,2.87,4.16,4.16,0,0,0,1.91-.37V20c-.35.15-2.06.66-2.06-1V15h2.06V12.66h-2.06Z" />
                    <polygon points="116.25 11.7 118.98 11.13 118.98 8.97 116.25 9.54 116.25 11.7" />
                    <rect x="116.25" y="12.61" width="2.73" height="9.64" />
                  </svg>
                </div>
              </div>
              <div className="tips">
                En cliquant sur payer par carte bleue, j'accepte les conditions
                générales de vente.
              </div>
            </>
          )}
          {channel === 'paypal' && (
            <>
              <div className="ctas">
                <button className="buy" type="submit" style={{ width: '100%' }}>
                  Payer par Paypal
                </button>
                {paymentError && (
                  <div
                    style={{
                      padding: '5px',
                      textAlign: 'center',
                      color: 'red',
                    }}
                  >
                    {paymentError}
                  </div>
                )}
              </div>
              <div className="tips">
                En cliquant sur payer par Paypal, j'accepte les conditions
                générales de vente.
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default function Checkout() {
  const basket = useBasket();
  const store = useStore();
  const [openCoupon, setOpenCoupon] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isCreatedOrder, setIsCreatedOrder] = useState(false);
  const [order, setOrder] = useState(null);

  const onSubmitCouponCode = (event) => {
    event.preventDefault();
    basket.addCouponCode(coupon).catch((error) => {
      // @todo better error ?
      console.log(error);
      alert('Le coupon ne peut être ajouté.');
    });
    setOpenCoupon(false);
    setCoupon('');
  };

  function onSuccess(ord) {
    setOrder(ord);
    setIsCreatedOrder(true);
    basket.reset();
  }

  function onError(errorMessage) {
    setIsCreatingOrder(false);
  }

  function onLoad() {
    setIsCreatingOrder(true);
  }

  if (isCreatedOrder) {
    return (
      <GuestLayout>
        <Helmet>
          <title>{store.get('store').name} • Panier</title>
        </Helmet>
        <div className="success-page">
          <img src="/assets/img/postcheckout.png" alt="" />
          <div className="message">
            <h1>Merci !</h1>
            <h2>
              Votre commande est bien enregistrée et porte le numéro{' '}
              <strong>#{order ? order.number : ''}</strong>
            </h2>
            <br />
            <br />
            <div style={{ fontSize: '1.1em' }}>
              Vous allez recevoir un email de confirmation d'ici quelques
              instants sur votre email.
            </div>
          </div>
        </div>
      </GuestLayout>
    );
  }

  if (basket.basket.lines.length === 0) {
    return (
      <GuestLayout>
        <div className="checkout-page empty" style={{ padding: '50px 0px' }}>
          Votre panier est vide
        </div>
      </GuestLayout>
    );
  }

  return (
    <GuestLayout>
      <Helmet>
        <title>{store.get('store').name} • Panier</title>
      </Helmet>
      <div className="checkout-page">
        {isCreatingOrder && (
          <div
            className="creating-loader"
            style={{
              padding: '50px 0px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader />
            <div style={{ padding: '20px' }}>Validation de votre commande</div>
          </div>
        )}

        <div className="infos-mobile">
          <div className="infos">
            <div className="title">Besoin d’aide ?</div>
            <div className="desc">
              Notre service client est à votre disposition du lundi au vendredi.
              Ecrivez à <a href="mailto:sav@wiltee.com">sav@wiltee.com</a>.
            </div>
            <div className="title">Quand vais-je recevoir mon produit ?</div>
            <div className="desc">
              Le délai de livraison est entre 9 et 12 jours ouvrables après
              avoir passé commande.
            </div>
          </div>
        </div>

        <Elements stripe={stripePromise}>
          <CheckoutForm
            onLoad={() => onLoad()}
            onSuccess={(data) => onSuccess(data)}
            onError={(errorMessage) => onError(errorMessage)}
          />
        </Elements>

        {/*         <div className="mobile-coupon" style={{ marginBottom: '20px' }}>
          {!openCoupon ? (
            <div style={{ flex: 1 }}>
              <label
                htmlFor="couponCode"
                style={{ paddingBottom: '4px', display: 'block' }}
              >
                Coupon de réduction
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="text blue" style={{ flex: 1 }}>
                  {basket.basket.coupon ? (
                    <div
                      style={{
                        padding: '10px',
                        border: '1px dashed grey',
                        borderRadius: '4px',
                        display: 'flex',
                      }}
                    >
                      <span style={{ color: 'grey' }}>
                        {basket.basket.coupon.code}
                      </span>
                    </div>
                  ) : (
                    <form className="form" onSubmit={onSubmitCouponCode}>
                      <div className="box">
                        <input
                          type="text"
                          id="couponCode"
                          placeholder="123456"
                          value={coupon}
                          onChange={(event) => setCoupon(event.target.value)}
                        />
                        <button disabled={coupon.length === 0}>Valider</button>
                      </div>
                    </form>
                  )}
                </div>
                <div
                  className="price"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px 5px',
                  }}
                  onClick={() => basket.removeCoupon()}
                >
                  {basket.basket.coupon && (
                    <>
                      <svg
                        style={{ width: '20px' }}
                        version="1.1"
                        viewBox="0 0 20 20"
                        x="0px"
                        y="0px"
                      >
                        <g>
                          <path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path>
                        </g>
                      </svg>
                    </>
                  )}
                </div>
              </div>

              {basket.basket.coupon && (
                <div
                  style={{
                    fontSize: '0.9em',
                    color: 'green',
                    padding: '2px 0px',
                  }}
                >
                  Votre coupon est validé.
                </div>
              )}
            </div>
          ) : (
            <form className="form" onSubmit={onSubmitCouponCode}>
              <label htmlFor="couponCode">Entrez votre coupon</label>
              <div className="box">
                <input
                  type="text"
                  id="couponCode"
                  placeholder="123456"
                  value={coupon}
                  onChange={(event) => setCoupon(event.target.value)}
                />
                <button disabled={coupon.length === 0}>Valider</button>
              </div>
            </form>
          )}
        </div> */}

        <div className="basket">
          <div className="wrapper">
            <div className="prices">
              <div className="lines">
                {basket.basket.lines.map((line, cpt) => (
                  <div key={cpt} className="line">
                    <img src={line.product.image.url} alt="" />
                    <div className="text">
                      {line.product.name} • {line.color.libelle} •{' '}
                      {line.size.libelle}
                      <br />
                      <span style={{ color: 'grey', fontSize: '0.8em' }}>
                        Quantité : {line.quantity}
                      </span>
                    </div>
                    <div className="price">{line.product.price} €</div>
                  </div>
                ))}
              </div>
              {/*               <div className="part desktop-coupon">
                {!openCoupon ? (
                  <>
                    <div
                      className="text blue"
                      onClick={() => setOpenCoupon(true)}
                    >
                      {basket.basket.coupon ? (
                        <div>
                          Coupon{' '}
                          <span style={{ color: 'grey' }}>
                            {basket.basket.coupon.code}
                          </span>
                        </div>
                      ) : (
                        <>Ajouter un coupon de réduction</>
                      )}
                    </div>
                    <div className="price">
                      {basket.basket.coupon && (
                        <>- {basket.basket.coupon.amount} €</>
                      )}
                    </div>
                  </>
                ) : (
                  <form className="form" onSubmit={onSubmitCouponCode}>
                    <label htmlFor="couponCode">Entrez votre coupon</label>
                    <div className="box">
                      <input
                        autoFocus
                        type="text"
                        id="couponCode"
                        placeholder="123456"
                        value={coupon}
                        onChange={(event) => setCoupon(event.target.value)}
                      />
                      <button disabled={coupon.length === 0}>Valider</button>
                    </div>
                  </form>
                )}
              </div>
              {basket.basket.coupon && (
                <div className="part mobile-coupon" style={{ margin: '0px' }}>
                  <div className="text blue">
                    <div>
                      Coupon{' '}
                      <span style={{ color: 'grey' }}>
                        {basket.basket.coupon.code}
                      </span>
                    </div>
                  </div>
                  <div className="price">- {basket.basket.coupon.amount} €</div>
                </div>
              )} */}
              <div className="part">
                <div className="text">Frais de livraison</div>
                <div className="price">{basket.getShippingPrice()} €</div>
              </div>
              <div className="part">
                <div className="text">Total TTC</div>
                <div className="price">{basket.getTotalTTC()} €</div>
              </div>
            </div>

            <div className="infos">
              <div className="title">Besoin d’aide ?</div>
              <div className="desc">
                Notre service client est à votre disposition du lundi au
                vendredi. Ecrivez à{' '}
                <a href="mailto:sav@wiltee.com">sav@wiltee.com</a>.
              </div>
              <div className="title">Quand vais-je recevoir mon produit ?</div>
              <div className="desc">
                Le délai de livraison est entre 9 et 12 jours ouvrables après
                avoir passé commande.
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  );
}
