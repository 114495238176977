import React, { useContext, createContext } from 'react';

const storeContext = createContext();
storeContext.displayName = 'Auth';

export function ProvideStore({ children, store }) {
  /*   if (store.store === undefined) {
    store.add('store', {
      id: 58,
      name: 'Madame Connasse',
      description: null,
      logo: { url: 'https://wiltee.com/media/header/58/logo_58.png' },
      facebookPixelId: '',
      adSpace: {
        enabled: false,
        image: { url: 'https://wiltee.com/media/store/add/null' },
        link: '',
        text: '',
        textprice: '',
      },
      cover: {
        enabled: true,
        url: 'https://wiltee.com/media/header/58/5d931f507e306_58.png',
      },
      favicon: {
        url: 'https://wiltee.com/media/store/favicons/5b1e5504091d1_58.png',
      },
      facebookUrl: 'https://www.facebook.com/Madameconnasse0987654321/',
      instagramUrl: 'https://www.instagram.com/madame_connasse_off/?hl=fr',
      twitterUrl: '',
      externalLink: {
        url: 'https://www.facebook.com/Madameconnasse0987654321/',
        label: 'FACEBOOK',
      },
      primaryColor: '#000',
      secondaryColor: '#BD0041',
      otherColor: '#FFF',
    });
  } */

  const auth = useProvideStore(store);
  return <storeContext.Provider value={auth}>{children}</storeContext.Provider>;
}

export const useStore = () => {
  return useContext(storeContext);
};

function useProvideStore(store) {
  const add = (key, data) => {
    store.add(key, data);
  };

  const remove = (key) => {
    store.remove(key);
  };

  const get = (key, remove = false) => {
    return store.get(key, remove);
  };

  return {
    add,
    remove,
    get,
  };
}
