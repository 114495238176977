import axios from 'axios';
import https from 'https';

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

const findStore = async (storeDomain) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}`
      : `https://api.wiltee.com/store/${storeDomain}`,
    { httpsAgent }
  );

  return data;
};

const findCampaigns = async (storeDomain, page = 1, collection = null) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/campaigns?page=${page}${
          collection ? `&collection=${collection}` : ''
        }`
      : `https://api.wiltee.com/store/${storeDomain}/campaigns?page=${page}${
          collection ? `&collection=${collection}` : ''
        }`,
    { httpsAgent }
  );

  return data;
};

const findCampaign = async (storeDomain, slug) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/campaigns/${slug}`
      : `https://api.wiltee.com/store/${storeDomain}/campaigns/${slug}`,
    { httpsAgent }
  );

  return data;
};

const generatePaymentIntent = async (storeDomain, body) => {
  const { data } = await axios.post(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/checkout/generate-payment-intent`
      : `https://api.wiltee.com/store/${storeDomain}/checkout/generate-payment-intent`,
    body,
    { httpsAgent }
  );

  return data;
};

const checkCoupon = async (storeDomain, couponCode) => {
  const { data } = await axios.get(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/checkout/check-coupon/${couponCode}`
      : `https://api.wiltee.com/store/${storeDomain}/checkout/check-coupon/${couponCode}`,
    { httpsAgent }
  );

  return data;
};

const shippingPrice = async (storeDomain, data) => {
  const response = await axios.post(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/checkout/shipping-price`
      : `https://api.wiltee.com/store/${storeDomain}/checkout/shipping-price`,
    data,
    { httpsAgent }
  );

  return response.data;
};

const newOrder = async (storeDomain, data) => {
  const response = await axios.post(
    process.env.REACT_APP_ENV === 'DEV'
      ? `http://localhost:3001/store/${storeDomain}/checkout/new-order`
      : `https://api.wiltee.com/store/${storeDomain}/checkout/new-order`,
    data,
    { httpsAgent }
  );

  return response.data;
};

export {
  findCampaigns,
  findCampaign,
  findStore,
  generatePaymentIntent,
  checkCoupon,
  shippingPrice,
  newOrder,
};
