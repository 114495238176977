import React from "react";

import routes from "./config/route";

import { ProvideRouter } from "./hooks/router/Router";
import Route from "./hooks/router/Route";
import { ProvideAuth } from "./hooks/auth/Auth";
import { ProvideBasket } from "./hooks/basket/Basket";

import Login from "./pages/login/Login";
import ErrorPage from "./pages/errorPage/ErrorPage";

export default function App({ route }) {
  return (
    <ProvideAuth>
      <ProvideRouter routes={routes} defaultRoute={route}>
        <ProvideBasket>
          <Route notAuthorized={Login} notFound={ErrorPage} />
        </ProvideBasket>
      </ProvideRouter>
    </ProvideAuth>
  );
}
