import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { canUseDOM } from 'exenv';

import GuestLayout from '../layouts/GuestLayout';
import { Link } from '../hooks/router';
import { useStore } from '../hooks/store';

import './collection.scss';
import './home.scss';

export default function Collection({ route }) {
  const store = useStore();
  const [campaigns, setCampaigns] = useState([]);
  const [collection, setCollection] = useState(store.get('store').collections.find(col => col.slug === route.params.slug));
  const [isLoading, setIsLoading] = useState(true);

  if (!canUseDOM && isLoading) {
    setCampaigns(route.data.campaigns); // Preloaded data
    
    // Initial state to preload client
    store.add('campaigns', route.data.campaigns);

    setIsLoading(false);
  }

  useEffect(() => {
    setCollection(store.get('store').collections.find(col => col.slug === route.params.slug));
    if (!store.get('campaigns')) {
      axios.get(`/_api/campaigns?collection=${route.params.slug}&t=${Date.now()}`).then(({ data }) => {
        setCampaigns(data);
        setIsLoading(false);
      });
    } else {
      setCampaigns(store.get('campaigns', true));
      setIsLoading(false);
    }
  }, [route.params.slug]);

  if (collection === null) {
    return (
      <GuestLayout>
        <div className="home-page collection-page">
          La collection n'existe pas !
        </div>
      </GuestLayout>
    )
  }

  return (
    <GuestLayout>
      <div className="home-page collection-page">
        <div className="title">
          {collection.name}
        </div>
        <div className="campaigns">
          {
            campaigns.map(campaign => (
              <Link key={campaign.id} to="campaign" params={{ slug: campaign.slug }} className="campaign">
                <img className="first" src={campaign.images[0].url} alt=""/>
                { campaign.images[1] &&
                  <img className="second" src={campaign.images[1].url} alt=""/>
                }
                <span className="name">{campaign.name}</span>
                <span className="price">{campaign.price} €</span>
              </Link>
            ))
          }
        </div>
      </div>
    </GuestLayout>
  );
};
