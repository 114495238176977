import moment from 'moment';
import { canUseDOM } from 'exenv';

function objectToString(object, prefix) {
  return Object.keys(object).map(key => ({ key, value: object[key] })).reduce((paramsArray, { key, value }) => {
    const keyString = prefix ? `${prefix}[${key}]` : key;
    if (value === null) {
      return paramsArray;
    }
    if (typeof value === 'boolean') {
      if (value === true) {
        return [...paramsArray, `${keyString}`];
      }
    } else if (typeof value === 'string') {
      if (value !== '') {
        return [...paramsArray, `${keyString}=${value}`];
      }
    } else if (moment.isDate(value) || moment.isMoment(value)) {
      if (value !== '') {
        return [...paramsArray, `${keyString}=${moment(value).format('DD-MM-YYYY')}`];
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        return [...paramsArray, `${keyString}=${value.join(',')}`];
      }
    } else if (typeof value === 'object') {
      return [...paramsArray, objectToString(value, keyString)];
    } else {
      return [...paramsArray, `${keyString}=${value}`];
    }
    return paramsArray;
  }, []).join('&')
}

export const queryToUrl = (params) => {
  if (params === undefined) {
    return '';
  }

  return '?' + objectToString(params);
};

export const getRouteFromUrl = async (urlPath, routesConfig, queryString = '', defaultRoute) => {
  let route = routesConfig
    .find(page => {
      return (
        new RegExp(`^${
          page.url//.replace('*', '[a-zA-Z0-9-]{0,}')
            .replace(/:[^/]*/, '[a-zA-Z0-9-]{1,}') 
            + /* for sub route */ ( page.children ? '(.*)?' : '$')
        }$`)
      )
      .test(urlPath)
    });

  if (!route) {
    if (defaultRoute !== undefined) {
      return defaultRoute;
    } else {
      const defaultRoute = routesConfig.find(route => route.name === '_default') 
      if (defaultRoute) {
        return defaultRoute;
      }
      throw new Error('No route found');
    }
  }

  route = Object.assign({}, route);
  
  const params = route.url
    .split('/')
    .map((value, cpt) => ({ cpt, value }))
    .filter(({ value }) => value[0] === ':')
    .reduce((acc, param) => Object.assign({}, acc, { [param.value.replace(':', '')]: urlPath.split('/')[param.cpt] } ), {});
    
  if (route.children !== undefined) {
    const realUrl = Object.keys(params).reduce((realUrl, keyParam) => realUrl.replace(`:${keyParam}`, params[keyParam]), route.url);
    route.subRoute = await getRouteFromUrl(urlPath.replace(realUrl, ''), route.children, queryString);
  }

  if (queryString) {
    route.query = {};
    const search = new URLSearchParams(queryString);
    for(var pair of search.entries()) {
      if (pair[1] === '') {
        pair[1] = true;
      } else if (pair[0] !== 'search' && pair[1].match(/,/) !== null) {
        pair[1].split(',');
      }
      route.query[pair[0]] = pair[1];
    }
  }

  route = Object.assign({}, route, { params })

  // Preloaded data in SSR
  if (!canUseDOM) {
    if (route.fetchData) {
      route.data = await route.fetchData(route);
    }
  }

  return route;
};

export const getRouteFromUrlSync = (urlPath, routesConfig, queryString = '') => {
  let route = routesConfig
    .find(page => {
      return (
        new RegExp(`^${
          page.url//.replace('*', '[a-zA-Z0-9-]{0,}')
            .replace(/:[^/]*/, '[a-zA-Z0-9-]{1,}') 
            + /* for sub route */ ( page.children ? '(.*)?' : '$')
        }$`)
      )
      .test(urlPath)
    });

  if (!route) {
    const defaultRoute = routesConfig.find(route => route.name === '_default') 
    if (defaultRoute) {
      return defaultRoute;
    }
    throw new Error('No route found');
  }

  route = Object.assign({}, route);
  
  const params = route.url
    .split('/')
    .map((value, cpt) => ({ cpt, value }))
    .filter(({ value }) => value[0] === ':')
    .reduce((acc, param) => Object.assign({}, acc, { [param.value.replace(':', '')]: urlPath.split('/')[param.cpt] } ), {});
    
  if (route.children !== undefined) {
    const realUrl = Object.keys(params).reduce((realUrl, keyParam) => realUrl.replace(`:${keyParam}`, params[keyParam]), route.url);
    route.subRoute = getRouteFromUrlSync(urlPath.replace(realUrl, ''), route.children, queryString);
  }

  if (queryString) {
    route.query = {};
    const search = new URLSearchParams(queryString);
    for(var pair of search.entries()) {
      if (pair[1] === '') {
        pair[1] = true;
      } else if (pair[0] !== 'search' && pair[1].match(/,/) !== null) {
        pair[1].split(',');
      }
      route.query[pair[0]] = pair[1];
    }
  }

  return Object.assign({}, route, { params });
};
