import React, { useState, useEffect, useContext, createContext, useRef } from "react";
import { createBrowserHistory } from 'history';
import { canUseDOM } from 'exenv';

import { queryToUrl, getRouteFromUrlSync } from './utils';

const history = canUseDOM ? createBrowserHistory() : null;

const routerContext = createContext();
routerContext.displayName = 'Router';

export function ProvideRouter({ children, routes, defaultRoute = false }) {
  const router = useProvideRouter(routes, defaultRoute);
  return <routerContext.Provider value={router}>{children}</routerContext.Provider>;
}

export const useRouter = () => {
  return useContext(routerContext);
};

export const withRouter = Component => {
  return (
    <routerContext.Consumer>
      {context => <Component route={context} />}
    </routerContext.Consumer>
  );
};

function useProvideRouter(routesConfig, defaultRoute = null) {
  const [route, setRoute] = useState(canUseDOM ? false : defaultRoute);
  const eventListeners = useRef({
    'routeChanged': [],
  });
  
  useEffect(() => {
    // Default route
    try {
      setRoute(getRouteFromUrlSync(window.location.pathname, routesConfig, window.location.search));
    } catch (error) {
      setRoute(null);
    }

    let unlisten;
    if (canUseDOM) {
      unlisten = history.listen(() => {
        try {
          setRoute(getRouteFromUrlSync(window.location.pathname, routesConfig, window.location.search));
        } catch (error) {
          setRoute(null);
        }
      });
    }
    return () => {
      if (canUseDOM) {
        unlisten();
      }
    };
  }, []);

  useEffect(() => {
    fireEvent('routeChanged', { route });
  }, [route]);

  const onRouteChanged = (listener) => {
    eventListeners.current.routeChanged.push(listener);
  };

  const fireEvent = (eventName, params) => {
    if (eventListeners.current[eventName]) {
      eventListeners.current[eventName].forEach((listener) => {
        listener(params);
      });
    }
  }

  const goBack = () => {
    // @todo Add a param to detect if it is still in the app, otherwise, go to default route (like dashboard)
    // ^ param will be used by full page by example
    if (canUseDOM) {
      history.goBack();
    }
  };
  
  const goForward = () => {
    if (canUseDOM) {
      history.goForward();
    }
  };
  
  const goTo = (routeName, params = {}, query = {}) => {
    const newRoute = _findRoute(0, routeName.split('/'), routesConfig, params);
    setRoute(route);
    if (canUseDOM) {
      history.push(getUrl(newRoute));
    }
  };
  
  const getRoute = (routeName, params = {}, query = {}) => {
    return _findRoute(0, routeName.split('/'), routesConfig, params);
  };
  
  const getUrl = (route) => {
    let { url } = route;
    if (route.params) {
      Object.entries(route.params).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
      });
    }
  
    if (route.subRoute) {
      url += getUrl(route.subRoute);
    }
  
    return url;
  };

  const _findRoute = (cpt, routeKeys, routeList, params = {}) => {
    let route = routeList.find(routeItem => routeItem.name === routeKeys[cpt]);
    if (!route) {
      throw new Error('No route found');
    }
  
    route = Object.assign({}, route);
  
    Object.keys(params).forEach(paramKey => {
      if (route.url.match(new RegExp(`:${paramKey}`))) {
        if (route.params === undefined) {
          route.params = {};
        }
        route.params[paramKey] = params[paramKey];
      }
    });
  
    if (route.children !== undefined) {
      if (cpt === routeKeys.length - 1) {
        routeKeys.push('_default');
      }
      const subRoute = _findRoute(cpt+1, routeKeys, route.children, params);
      route.subRoute = subRoute;
    }
  
    return route;
  }

  const setQuery = (queryString) => {
    if (canUseDOM) {
      history.replace(getUrl(route) + queryToUrl(queryString));
    }
  }
  
  return {
    route,
    goTo,
    goBack,
    goForward,
    getRoute,
    getUrl,
    setQuery,
    queryToUrl,
    onRouteChanged,
  };
};
