import _ from 'lodash';

class Store {
  constructor(defaultData = {}) {
    this.data = defaultData;
  }

  add(key, data) {
    _.set(this.data, key, data);
  }

  remove(key) {
    delete this.data[key];
  }

  get(key, remove = false) {
    const data = _.get(this.data, key);
    if (remove) {
      this.remove(key); // Used when app hydrate at first load, after, need to refresh data
    }
    return data;
  }

  renderToString() {
    return JSON.stringify(this.data);
  }
}

export const createStore = (defaultData = {}) => {
  return new Store(defaultData);
};
