import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { ProvideStore, createStore } from './hooks/store';
import * as serviceWorker from './serviceWorker';

const isLocalHost = (hostname) =>
  !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

if (
  typeof window !== 'undefined' &&
  window.location &&
  window.location.protocol === 'http:' &&
  !isLocalHost(window.location.hostname)
) {
  window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
} else {
  ReactDOM.hydrate(
    <ProvideStore store={createStore(window.__STATE__)}>
      <App />
    </ProvideStore>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
