import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

import { setCookie, removeCookie, getCookie } from "./session";

const CookieTokenName = "xTokStWil";

const authContext = createContext();
authContext.displayName = "Auth";

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(getCookie(CookieTokenName));
  const [isAuthenticated, setIsAuthenticated] = useState(
    getCookie(CookieTokenName) !== null
  );

  useEffect(() => {
    if (getCookie(CookieTokenName)) {
      setIsAuthenticated(true);
      axios
        .get(`http://localhost:5010/me`, {
          headers: { Authorization: `Bearer ${getCookie(CookieTokenName)}` },
        })
        .then(({ data }) => {
          setUser(data);
        });
    }
  }, []);

  const signin = (email, password) => {
    axios
      .post(`http://localhost:5010/authenticate`, { email, password })
      .then(({ data }) => {
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }
        setUser(data.user);
        setToken(data.token);
        setCookie(CookieTokenName, data.accessToken);
      });
  };

  const signup = (email, password) => {
    throw new Error("Not implemented");
  };

  const signout = () => {
    removeCookie(CookieTokenName);
    setUser(null);
    setToken(null);
    setIsAuthenticated(false);
  };

  const forgotPassword = (email) => {
    throw new Error("Not implemented");
  };

  const confirmPasswordReset = (code, password) => {
    throw new Error("Not implemented");
  };

  return {
    token,
    user,
    isAuthenticated,
    signin,
    signup,
    signout,
    forgotPassword,
    confirmPasswordReset,
  };
}
