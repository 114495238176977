import React, { useEffect, useRef, useState } from 'react';
import axios, { CancelToken } from 'axios';
import { canUseDOM } from 'exenv';

import './home.scss';
import GuestLayout from '../layouts/GuestLayout';
import { Link } from '../hooks/router';
import { useStore } from '../hooks/store';

export default function Home({ route }) {
  const store = useStore();
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const source = useRef(null);
  const page = useRef(1);
  const noMore = useRef(false);

  if (!canUseDOM && isLoading) {
    setCampaigns(route.data); // Preloaded data
    // Initial state to preload client
    store.add('campaigns', route.data);

    setIsLoading(false);
  }

  useEffect(() => {
    if (!store.get('campaigns')) {
      source.current = CancelToken.source();
      axios
        .get(`/_api/campaigns?page=${page.current}&t=${Date.now()}`, {
          cancelToken: source.current.token,
        })
        .then(({ data }) => {
          setCampaigns(data);
          setIsLoading(false);
        });
    } else {
      setCampaigns(store.get('campaigns', true));
      setIsLoading(false);
    }
  }, [store]);

  useEffect(() => {
    const onScroll = () => {
      // list height
      const listHeight = document.querySelector('html').clientHeight;
      // list top position
      const scrollTop = document.querySelector('html').scrollTop;
      // height of the list
      const scrollHeight = document.querySelector('html').scrollHeight;
      if (scrollTop + listHeight + 750 >= scrollHeight) {
        if (!isLoading && !source.current && !noMore.current) {
          page.current++;
          source.current = CancelToken.source();
          axios
            .get(`/_api/campaigns?page=${page.current}&t=${Date.now()}`, {
              cancelToken: source.current.token,
            })
            .then(({ data }) => {
              if (data.length === 0) noMore.current = true;
              setCampaigns((prevData) => [...prevData, ...data]);
              setIsLoading(false);
              source.current = null;
            });
        }
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isLoading, source]);

  return (
    <GuestLayout>
      {store && store.get('store').name ? (
        <div className="home-page">
          {store.get('store').cover.enabled && (
            <img
              src={store.get('store').cover.url}
              className="cover"
              alt="cover"
            />
          )}
          <div className="campaigns">
            {store.get('store').adSpace.enabled && (
              <a
                href={store.get('store').adSpace.link}
                className="campaign"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={store.get('store').adSpace.image.url} alt="" />
                <span className="name">{store.get('store').adSpace.text}</span>
                {store.get('store').adSpace.textprice && (
                  <span className="price">
                    {store.get('store').adSpace.textprice} €
                  </span>
                )}
              </a>
            )}
            {campaigns?.map((campaign) => (
              <Link
                key={campaign?.id}
                to="campaign"
                params={{ slug: campaign?.slug }}
                className="campaign"
              >
                <img className="first" src={campaign?.images[0]?.url} alt="" />
                {campaign?.images[1] && (
                  <img
                    className="second"
                    src={campaign?.images[1]?.url}
                    alt=""
                  />
                )}
                <span className="name">{campaign?.name}</span>
                <span className="price">{campaign?.price} €</span>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <>test</>
      )}
    </GuestLayout>
  );
}
