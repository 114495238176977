import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { canUseDOM } from 'exenv';
import { Helmet } from 'react-helmet';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import './campaign.scss';
import GuestLayout from '../layouts/GuestLayout';
import { useBasket } from '../hooks/basket/Basket';
import { useStore } from '../hooks/store/Store';

export default function Campaign({ route }) {
  const basket = useBasket();
  const store = useStore();
  const [campaign, setCampaign] = useState(null);
  const [tab, showTab] = useState('description');
  const [currentProduct, setCurrentProduct] = useState(
    campaign ? campaign.products[0] : null
  );
  const [currentColor, setCurrentColor] = useState(
    campaign && currentProduct ? currentProduct.colors[0] : null
  );
  const [currentImage, setCurrentImage] = useState(
    campaign && currentProduct ? currentProduct.colors[0].images[0] : null
  );
  const [currentSize, setCurrentSize] = useState(
    campaign && currentProduct ? currentProduct.sizes[0] : null
  );
  const [openSizeGuide, setOpenSizeGuide] = useState(false);

  if (!canUseDOM) {
    if (campaign === null) {
      setCampaign(route.data); // Preloaded data
      setCurrentProduct(route.data.products[0]);
      setCurrentColor(route.data.products[0].colors[0]);
      setCurrentImage(route.data.products[0].colors[0].images[0]);
      setCurrentSize(route.data.products[0].sizes[0]);

      // Initial state to preload client
      store.add('campaign', route.data);
    }
  }

  useEffect(() => {
    if (!store.get('campaign')) {
      axios
        .get(`/_api/campaign/${route.params.slug}?t=${Date.now()}`)
        .then(({ data }) => {
          setCampaign(data);
        });
    } else {
      setCampaign(store.get('campaign', true));
    }
  }, [route]);

  useEffect(() => {
    if (currentProduct) {
      setCurrentColor(currentProduct.colors[0]);
      setCurrentSize(currentProduct.sizes[0]);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (campaign) {
      setCurrentProduct(campaign.products[0]);
    }
  }, [campaign]);

  useEffect(() => {
    if (currentColor) {
      setCurrentImage(currentColor.images[0]);
    }
  }, [currentColor]);

  function onChangeProduct(event) {
    const productId = parseInt(event.target.value, 10);
    const selectedProduct = campaign.products.find((p) => p.id === productId);
    setCurrentProduct(selectedProduct);
  }

  function onClickProduct(product) {
    setCurrentProduct(product);
  }

  function addToBasket(event) {
    basket.add(campaign, currentProduct, currentColor, currentSize, 1);
  }

  return (
    <GuestLayout>
      <Helmet>
        <title>
          {store.get('store').name} • {campaign ? campaign.name : 'Chargement'}
        </title>
        <meta
          name="description"
          content={campaign ? campaign.description : 'Chargement'}
        />
      </Helmet>
      <div className="campaign-page">
        <div className="campaign">
          {campaign ? (
            <div className="show-mobile">
              <div className="name">{campaign.name}</div>
            </div>
          ) : (
            <>
              <div
                style={{
                  width: '100%',
                  height: '32px',
                  marginBottom: '10px',
                  background:
                    'url(https://wiltee.com/assets/img/loader-bar.gif',
                }}
              />
            </>
          )}
          <div className="gallery">
            {currentColor && (
              <Carousel
                interval={99999999999}
                prevIcon={<div class="carousel-prev" />}
                nextIcon={<div className="carousel-next" />}
              >
                {currentColor.images.map((image, cpt) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={image.url}
                      alt="Product view"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
            <div className="previews">
              {currentColor && (
                <>
                  {currentColor.images.map((image, cpt) => (
                    <img
                      key={cpt}
                      src={image.url}
                      onClick={() => setCurrentImage(image)}
                      alt="Product view"
                    />
                  ))}
                </>
              )}
            </div>
            {currentImage && (
              <div className="show">
                <img src={currentImage.url} alt="Product view" />
              </div>
            )}
          </div>
          <div className="informations">
            {campaign ? (
              <>
                <div className="name">{campaign.name}</div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: campaign.description.replace(
                      /(?:\r\n|\r|\n)/g,
                      '<br />'
                    ),
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    height: '32px',
                    marginBottom: '10px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
                <div
                  style={{
                    width: '220px',
                    height: '20px',
                    marginBottom: '10px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
                <div
                  style={{
                    width: '220px',
                    height: '20px',
                    marginBottom: '10px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
                <div
                  style={{
                    width: '220px',
                    height: '20px',
                    marginBottom: '20px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
              </>
            )}

            <div className="form">
              {currentProduct && currentColor && currentSize ? (
                <>
                  <div className="part">
                    <label htmlFor="products">Produits disponibles</label>
                    <select
                      name="products"
                      id="products"
                      onChange={onChangeProduct}
                      value={currentProduct.id}
                    >
                      {campaign.products.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.reference.name} • {product.price} €
                        </option>
                      ))}
                    </select>
                    <div className="previews">
                      {campaign.products.map((product) => (
                        <img
                          key={product.id}
                          className={`preview${
                            currentProduct.id === product.id ? ' active' : ''
                          }`}
                          src={product.colors[0].images[0].url}
                          alt="preview"
                          onClick={() => onClickProduct(product)}
                        />
                      ))}
                    </div>
                    <label htmlFor="colors">
                      Couleurs: {currentColor.libelle}
                    </label>
                    <div className="colors">
                      {currentProduct.colors.map((color) => (
                        <div
                          key={color.id}
                          className={`color${
                            color.id === currentColor.id ? ' active' : ''
                          }`}
                          onClick={() => setCurrentColor(color)}
                        >
                          <div
                            style={{
                              background: color.image
                                ? `url(${color.image.url}) center center`
                                : '',
                              imageSize: 'cover',
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>

                    <label htmlFor="sizes">Tailles:</label>
                    <div className="sizes">
                      {currentProduct.sizes.map((size) => (
                        <div
                          key={size.id}
                          className={`size${
                            size.id === currentSize.id ? ' active' : ''
                          }`}
                          onClick={() => setCurrentSize(size)}
                        >
                          {size.libelle}
                        </div>
                      ))}
                    </div>
                    <div
                      className={`size-guide-cta${
                        openSizeGuide ? ' show' : ''
                      }`}
                      onClick={() =>
                        setOpenSizeGuide(
                          (prevOpenSizeGuide) => !prevOpenSizeGuide
                        )
                      }
                    >
                      Guide des tailles
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 256 256"
                        xmlSpace="preserve"
                      >
                        <polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128" />
                      </svg>
                    </div>
                    {openSizeGuide && (
                      <div className="size-guide">
                        <table>
                          <tr>
                            <td></td>
                            <td className="top">Largeur</td>
                            <td className="top">Hauteur</td>
                          </tr>
                          {currentProduct.sizes.map((size) => (
                            <tr>
                              <td>{size.libelle}</td>
                              <td>{size.width}cm</td>
                              <td>{size.height}cm</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="cta">
                    <div className="price">{currentProduct.price} €</div>
                    <button
                      onClick={addToBasket}
                      style={{
                        backgroundColor: store.get('store').secondaryColor
                          ? store.get('store').secondaryColor
                          : '#ffcd46',
                      }}
                    >
                      Acheter maintenant
                    </button>
                  </div>
                </>
              ) : (
                <div className="part">
                  <div
                    style={{
                      width: '100%',
                      height: '32px',
                      marginBottom: '10px',
                      background:
                        'url(https://wiltee.com/assets/img/loader-bar.gif',
                    }}
                  />
                  <div
                    style={{
                      width: '220px',
                      height: '20px',
                      marginBottom: '10px',
                      background:
                        'url(https://wiltee.com/assets/img/loader-bar.gif',
                    }}
                  />
                  <div
                    style={{
                      width: '220px',
                      height: '20px',
                      marginBottom: '10px',
                      background:
                        'url(https://wiltee.com/assets/img/loader-bar.gif',
                    }}
                  />
                  <div
                    style={{
                      width: '220px',
                      height: '20px',
                      marginBottom: '20px',
                      background:
                        'url(https://wiltee.com/assets/img/loader-bar.gif',
                    }}
                  />
                </div>
              )}
              <div className="infos">
                <div className="line">
                  <img src="https://wiltee.com/assets/img/check.png" alt="" />
                  Livraison estimée entre 9 et 12 jours
                </div>
                <div className="line">
                  <img src="https://wiltee.com/assets/img/check.png" alt="" />
                  Paiement sécurisé par carte bancaire
                </div>
              </div>
              <div className="cards">
                <ul className="card-list">
                  <li className="cardBrand-visa">Visa</li>
                  <li className="cardBrand-masterCard">Master Card</li>
                  <li className="cardBrand-americanExpress">
                    American Express
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {campaign ? (
          <div className="show-mobile">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: campaign.description }}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                width: '220px',
                height: '20px',
                marginBottom: '10px',
                background: 'url(https://wiltee.com/assets/img/loader-bar.gif',
              }}
            />
            <div
              style={{
                width: '220px',
                height: '20px',
                marginBottom: '10px',
                background: 'url(https://wiltee.com/assets/img/loader-bar.gif',
              }}
            />
            <div
              style={{
                width: '220px',
                height: '20px',
                marginBottom: '20px',
                background: 'url(https://wiltee.com/assets/img/loader-bar.gif',
              }}
            />
          </>
        )}
        <div className="more">
          <div className="container">
            <div className="list">
              <div
                className={`item${tab === 'description' ? ' active' : ''}`}
                onClick={() => showTab('description')}
              >
                Description
              </div>
              <div
                className={`item${tab === 'shipping' ? ' active' : ''}`}
                onClick={() => showTab('shipping')}
              >
                Livraison et retours
              </div>
              <div
                className={`item${tab === 'upkeep' ? ' active' : ''}`}
                onClick={() => showTab('upkeep')}
              >
                Entretien
              </div>
            </div>

            {campaign ? (
              <>
                {tab === 'description' && currentProduct && (
                  <div
                    className="tab"
                    dangerouslySetInnerHTML={{
                      __html: currentProduct.reference.description,
                    }}
                  />
                )}
                {tab === 'shipping' && (
                  <div className="tab">
                    Les commandes sont imprimées au moment de votre achat dans
                    notre atelier parisien.
                    <br />
                    Le délai de livraison estimé est entre 9 - 12 jours
                    ouvrables.
                    <br />
                    <br />
                    <br />
                    Les frais de port s'élèvent à :<br />
                    3.99 € pour les tee-shirts / débardeurs / tote bags + 2 €
                    pour chaque produit supplémentaire identique.
                    <br />
                    5.49 € pour les sweats + 2 € pour chaque produit
                    supplémentaire identique.
                    <br />
                    5.99 € pour les mugs / sweats à capuche + 2 € pour chaque
                    produit supplémentaire identique.
                    <br />
                    <br />
                    <br />
                    Les retours produits sont autorisés sous 2 conditions :
                    <br />
                    - Ils sont à votre charge
                    <br />
                    - Ils sont autorisés dans les 14 jours à réception de la
                    commande.
                    <br />
                  </div>
                )}
                {tab === 'upkeep' && (
                  <div>
                    <strong>TEXTILE :</strong>
                    <br />
                    <ul style={{ listStyleType: 'decimal' }}>
                      <li>
                        Retournez le produit avant de le laver (afin de ne pas
                        l’abîmer au lavage) ;
                      </li>
                      <li>Lavez-le à 30°C (pas plus chaud) ;</li>
                      <li>Ne dépassez pas les 400 tours/minute en machine ;</li>
                      <li>
                        Ne pas le passer au sèche-linge (pour éviter de faire
                        rétrécir le coton).
                      </li>
                    </ul>
                    <br />
                    <strong>MASQUE TISSU:</strong>
                    <br />
                    <ul style={{ listStyleType: 'decimal' }}>
                      <li>Lavable à 60°C.</li>
                    </ul>

                    <br />
                    <br />
                  </div>
                )}
              </>
            ) : (
              <div className="tab">
                <div
                  style={{
                    width: '100%',
                    height: '20px',
                    marginBottom: '10px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
                <div
                  style={{
                    width: '100%',
                    height: '20px',
                    marginBottom: '10px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
                <div
                  style={{
                    width: '100%',
                    height: '20px',
                    marginBottom: '20px',
                    background:
                      'url(https://wiltee.com/assets/img/loader-bar.gif',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </GuestLayout>
  );
}
