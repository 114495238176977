import React, { useEffect } from 'react';
import axios from 'axios';

import './tracking.scss';
import GuestLayout from '../layouts/GuestLayout';
import { getCookie } from '../hooks/auth/session';

export default function Tracking() {

  useEffect(() => {
  }, []);

  return (
    <GuestLayout>
      <div className="tracking-page">
      </div>
    </GuestLayout>
  );
};
